export const sectionDetails = [
  {
    "name": "aboutme",
    "backgroundColor": "#02010a",
    // "backgroundColor": "black",
    "fontColor": "#aac8fb"
    // "fontColor": "white"
  },
  {
    "name": "work",
    "backgroundColor": "#02010a",
    // "backgroundColor": "black",
    "fontColor": "#e6b1fc"
    // "fontColor": "white"
  },
  {
    "name": "projects",
    "backgroundColor": "#02010a",
    // "backgroundColor": "black",
    "fontColor": "#fdb1ab"
    // "fontColor": "white"
  }
]