export const workExperience = [
  {
    time: "November 2018 - December 2018",
    company: "UoA",
    position: "Summer Researcher",
    descriptions: [
      "Refactor a past student's project using React and Express.js"
    ]
  },
  {
    time: "December 2018 - February 2019",
    company: "e-Merge",
    position: "Intern",
    descriptions: [
      "Developed an internal scheduling service for field workers using Express.js",
      "Live testing of service showed that field workers could fit at least 1 extra job per day"
    ]
  },
  {
    time: "February 2019 - March 2019",
    company: "UoA",
    position: "Summer Researcher",
    descriptions: [
      "Develop a web application to help lecturers easily annotate electronic devices using React",
      "Feedback received from students was generally well received"
    ]
  },
  {
    time: "November 2019 - April 2020",
    company: "Perpetual Guardian",
    position: "Junior Programmer Analyst",
    descriptions: [
      "Develop and maintain React frontend and .Net backend codebases",
      "Integration testing with Selenium and unit testing with Jest",
      "Worked closely with clients to ensure designs and development of product went smoothly"
    ]
  },
  {
    time: "February 2021 - October 2021",
    company: "MSD",
    position: "Junior Developer",
    descriptions: [
      "Develop and maintain Ember frontend and Apache Camel backend codebases",
      "Collaborate with automation testers to ensure the product is ready for use",
      "JUnit testing and improving code coverage"
    ]
  },
  {
    time: "October 2021 - Present",
    company: "APLYiD",
    position: "Software Developer",
    descriptions: [
      "Develop and maintain both Vue frontend and Rails backend codebases",
      "Work closely with design lead to improve UX and UI",
      "Manage UK deployments and technical support for product"
    ]
  },
]