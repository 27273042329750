/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { a, useSpring } from "@react-spring/three";
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { THREE } from "enable3d";
import { useRef, useState } from 'react';
import { sectionDetails } from "../constants/sectionDetails";

export default function Model(props) {
  const group = useRef()
  const [active, setActive] = useState(false)

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 8) / 20, 0.1)
    group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 8) / 20, 0.1)
    group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 8) / 40, 0.1)
  })

  function positionViewMode(){
    if (props.viewMode === "desktop" || props.viewMode === "desktopLg") {
      return props.active ? [3.5, 1.4, 2.5] : [3.5, 5, 2.5]
    } else if (props.viewMode === "tablet") {
      return props.active ? [3, 1.4, 2.5] : [3, 5, 2.5]
    } else if (props.viewMode === "tabletSM") {
      return props.active ? [2.5, 1.4, 2.5] : [2.5, 5, 2.5]
    }
  }

  const { color, rotation, position, opacity } = useSpring({
    position: positionViewMode(),
    rotation: active ? [1.58, 0, Math.PI] : [1.58, 0, -Math.PI],
    color: active ? sectionDetails[0].fontColor : 'white',
    opacity: props.viewMode === "desktopLg" ? [1] : [0.5],
    onRest: () => props.enableScroll,
    reset: true
  })

  const { nodes, materials } = useGLTF('/aboutme.glb')

  return (
    <group ref={group} dispose={null}>
      <a.mesh
        geometry={nodes.Cylinder001.geometry}
        material={materials['Material.004']}
        mass={1}
        position={position}
        rotation={rotation}
        onClick={e => setActive(!active)}
        scale={[0.75, 0.1, 0.75]}
        receiveShadow={props.active}
        castShadow={props.active}
        >
          <a.meshStandardMaterial attach="material" color={color} opacity={opacity} transparent/>
        </a.mesh>
    </group>
  )
}

useGLTF.preload('/aboutme.glb')
