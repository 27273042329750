import cx from 'classnames';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { workExperience } from '../constants/workExperience';

interface IWorkProps {
  transitionColor: any,
  order: number,
  unlockScroll: any,
  viewMode: string
}

export default function WorkSection(props: IWorkProps) {
  const [inView, setInView] = useState(false)
  const [workOrder, setWorkOrder] = useState(0)

  function setInViewChange(inView: boolean, entry: any) {
    const { order } = props;
    if ( inView && order === 1 ) {
      props.transitionColor(inView, entry)
      props.unlockScroll()
    }

    setInView(inView)
  }

  function cycleWork(number: number) {
    let resOrder = workOrder + number
    if (resOrder >= 0 && resOrder < workExperience.length) {
      setWorkOrder(resOrder)
    }
  }

  return(
    <div className={cx({"work-anime-wrapper": true, active: props.order === 1})} id="work-container">
      <div className="work-container">
        <InView as="div" id="work" onChange={(inView, entry) => setInViewChange(inView, entry)} trackVisibility={true} delay={100}>
          <span className="title">Work</span>
        </InView>
        <div className={cx({
          "work-experience-section": true,
          "visible": inView
        })}>
          <div className="work-slider">
            <i className={cx({"fas fa-chevron-left": true, "inactive": workOrder === 0})} onClick={() => cycleWork(-1)}/>
            {
              workExperience.map((workExperience, i) => 
                <b key={`work-experience-title-${i}`} className={cx({"active": workOrder === i})}>{workExperience.company}</b>
              )
            }
            <i className={cx({"fas fa-chevron-right": true, "inactive": workOrder === workExperience.length - 1})} onClick={() => cycleWork(1)}/>
          </div>
          <div className="current-work">
            {
              workExperience.map((workExperience, i) => 
                <div className={cx({"work-circle": true, "active": workOrder === i})} onClick={() => setWorkOrder(i)}/>
              )
            }
          </div>
          {
            workExperience.map((workExperience, i) => 
              <div key={`work-experience-${i}`} className={cx({"work-experience": true, "active": workOrder === i})}>
                <b>{workExperience.position}</b>
                <span>{ workExperience.time }</span>
                <ul>
                  {
                    workExperience.descriptions.map(description => <li>{ description }</li>)
                  }
                </ul>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}