/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { a, useSpring } from "@react-spring/three";
import { useGLTF, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { THREE } from 'enable3d';
import { useRef, useState } from 'react';
import code from '../assets/code.png';
import { sectionDetails } from "../constants/sectionDetails";

export default function Model(props) {
  const group = useRef()
  const [active, setActive] = useState(false)

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 4) / 40, 0.1)
    group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 40, 0.1)
    group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 4) / 80, 0.1)
  })

  function positionViewMode(){
    if (props.viewMode === "desktop" || props.viewMode === "desktopLg") {
      return props.active ? [4, 1.4, 3.2] : [4, 5, 3.2]
    } else if (props.viewMode === "tablet") {
      return props.active ? [3.5, 1.4, 3.2] : [3.5, 5, 3.2]
    } else if (props.viewMode === "tabletSM") {
      return props.active ? [3, 1.4, 3.2] : [3, 5, 3.2]
    }
  }

  const { color, rotation, opacity, position } = useSpring({
    position: positionViewMode(),
    rotation: active ? [0, Math.PI, 0] : [0, -Math.PI, 0],
    color: active ? sectionDetails[2].fontColor : 'grey',
    opacity: props.viewMode === "desktopLg" ? [1] : [0.5],
    onRest: () => props.enableScroll,
    reset: true
  })

  const codeTexture = useTexture(code)

  const { nodes, materials } = useGLTF('/work.glb')
  return (
    <group ref={group} dispose={null}>
      <a.group scale={[0.34, 0.01, 0.30]} 
          position={position} 
          rotation={rotation} 
          onClick={e => setActive(!active)}
          color={color}
          receiveShadow={props.active}
          castShadow={props.active}
          transparent
          opacity={opacity}
      >
        <a.mesh geometry={nodes.Cube005.geometry} material={materials['Material.043']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={color} transparent opacity={opacity}/> </a.mesh>
        <a.mesh geometry={nodes.Cube005_1.geometry} material={materials['Material.044']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={color} transparent opacity={opacity}/> </a.mesh>
        <a.mesh geometry={nodes.Cube005_2.geometry} material={materials['Material.008']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={color} transparent opacity={opacity}/> </a.mesh>
        <a.mesh geometry={nodes.Cube005_3.geometry} material={materials['Material.040']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={color} transparent opacity={opacity}/> </a.mesh>
        <a.mesh geometry={nodes.Cube005_4.geometry} material={materials['Material.041']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={color} transparent opacity={opacity}/> </a.mesh>
        <a.mesh geometry={nodes.Cube005_5.geometry} material={materials.Material} receiveShadow={props.active} castShadow={props.active} />
        <a.mesh geometry={nodes.Cube005_5.geometry} material={codeTexture} receiveShadow={props.active} castShadow={props.active}>
          <a.meshBasicMaterial map={codeTexture} transparent opacity={opacity}/>
        </a.mesh>
        <a.mesh geometry={nodes.Cube005_6.geometry} material={materials['Material.002']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={'black'} transparent opacity={opacity}/> </a.mesh>
        <a.mesh geometry={nodes.Cube005_7.geometry} material={materials['Material.003']} receiveShadow={props.active} castShadow={props.active}> <a.meshStandardMaterial attach="material" color={'black'} transparent opacity={opacity}/> </a.mesh>
      </a.group>
    </group>
  )
}

useGLTF.preload('/work.glb')
