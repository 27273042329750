/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { a, useSpring } from "@react-spring/three";
import { useGLTF } from '@react-three/drei';
import { useRef, useState } from 'react';
import { sectionDetails } from "../constants/sectionDetails";

export default function Model(props) {
  const group = useRef()
  const [active, setActive] = useState(false)

  function positionViewMode(){
    if (props.viewMode === "desktop" || props.viewMode === "desktopLg") {
      return props.active ? [3.5, 1, 2.5] : [3.5, 5, 2.5]
    } else if (props.viewMode === "tablet") {
      return props.active ? [3, 1, 2.5] : [3, 5, 2.5]
    } else if (props.viewMode === "tabletSM") {
      return props.active ? [2, 1, 2.5] : [2, 5, 2.5]
    }
  }

  const { color, rotation, position, opacity } = useSpring({
    position: positionViewMode(),
    positionSecondStack: props.active ? [0.32, 0, 0] : [0.32, 0, 0],
    rotation: active ? [0, 2 * Math.PI, 0] : [0, 0, 0],
    color: active ? sectionDetails[1].fontColor : 'white',
    opacity: props.viewMode === "desktopLg" ? [1] : [0.025],
    onRest: () => props.enableScroll,
    reset: true
  })

  const { nodes } = useGLTF('/paperstack.glb')
  return (
    <a.group 
      ref={group} 
      scale={[2, 2, 2]}
      position={position} 
      rotation={rotation} 
      onClick={e => setActive(!active)}
      receiveShadow={props.active}
      castShadow={props.active}
      opacity={opacity}
    >
      <a.mesh
        geometry={nodes.Stack_of_Paper002.geometry}
        position={[0.32, 0, 0]}
        receiveShadow={props.active}
        castShadow={props.active}
        transparent
        opacity={opacity}
      >
        <a.meshStandardMaterial color={color} transparent opacity={opacity}/>
      </a.mesh>
      <a.mesh 
        geometry={nodes.Stack_of_Paper.geometry} 
        receiveShadow={props.active}
        castShadow={props.active}
        transparent
        opacity={opacity}
        >
        <a.meshStandardMaterial color={color} transparent opacity={opacity}/>
      </a.mesh>
    </a.group>
  )
}

useGLTF.preload('/paperstack.glb')
